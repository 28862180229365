'use client';

import {useTranslations} from 'next-intl';
import Link from 'next/link';
import {useEffect} from 'react';
import Header from '../../components/header/Header';
import logger from '../../logger';

export default function Error({
  error,
  reset,
}: {
  error: Error & {digest?: string};
  reset: () => void;
}) {
  const t = useTranslations('common');

  useEffect(() => {
    logger.error(error);
  }, [error]);

  return (
    <>
      <Header />
      <div className="tw-container tw-mx-auto tw-p-8">
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-4 tw-mb-8">
          <div className="tw-text-3xl tw-font-bold">{t('ErrorPage.error')}</div>
        </div>
        <Link href="/" className="tw-mt-8">
          {t('ErrorPage.return-to-home')}
        </Link>
      </div>
    </>
  );
}
